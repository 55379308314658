import React from 'react'
import './footer.css'
import {FiFacebook, FiInstagram, FiTwitter} from 'react-icons/fi'


const Footer = () => {
  return (
   <footer>
     <a href='#' className='footer__logo'>Ralph Etienne</a>

     <ul className='permaLinks'>
       <li><a href='#'>Home</a></li>
       <li><a href='#about'>About</a></li>
       <li><a href='#experience'>Experience</a></li>
       <li><a href='#portfolio'>Portfolio</a></li>
       <li><a href='#contact'>Contact</a></li>
     </ul>

     <div className='footer__social'>
       <a href='https://facebook.com'><FiFacebook/></a>
       <a href='https://instagram.com'><FiInstagram/></a>
       <a href='https://twitter.com'><FiTwitter/></a>
     </div>

     <div className='footer__copyright'>
       <small>&copy; Ralph Etienne Profile. All Rights Reserved.</small>
     </div>
   </footer>
  )
}

export default Footer