import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/04Todolist.jpeg'
import IMG2 from '../../assets/portfolio2.jpg'
import IMG3 from '../../assets/03casinonights.jpeg'
import IMG4 from '../../assets/03herovsvillians.jpeg'
import IMG5 from '../../assets/portfolio5.jpg'
import IMG6 from '../../assets/portfolio6.jpg'

const data = [
{
  id: 1,
  image: IMG1,
  title: 'Todo List Application',
  github: 'https://github.com/ralph534/todoList',
  demo: 'https://dribbble.com/alien_pixels'
},
{
  id: 2,
  image: IMG2,
  title: 'New Portfolio',
  github: 'https://github.com/ralph534/My-Portfolio',
  demo: 'https://dribbble.com/alien_pixels'
},
{
  id: 3,
  image: IMG3,
  title: 'Rails-Casino',
  github: 'https://github.com/ralph534/Rails-Casino',
  demo: 'https://dribbble.com/alien_pixels'
},
{
  id: 4,
  image: IMG4,
  title: 'Hero-Vs-Villian',
  github: 'https://github.com/ralph534/My-Portfolio',
  demo: 'https://dribbble.com/alien_pixels'
},
{
  id: 5,
  image: IMG5,
  title: 'New Profile',
  github: 'https://github.com/ralph534/My-Portfolio',
  demo: 'https://dribbble.com/alien_pixels'
},
{
  id: 6,
  image: IMG6,
  title: 'New Profile',
  github: 'https://github.com/ralph534/My-Portfolio',
  demo: 'https://dribbble.com/alien_pixels'
}
]





const Portfolio = () => {
  return (
    <section id='portfolio'>
        <h5>My Recent Work</h5>
        <h2>Portfolio</h2>

        <div className='container portfolio__container'>
           {
             data.map(({id, image, title, github, demo }) => {
               return (
                <article key={id} className='portfolio__item'>
                <div className='portfolio__item-image'>
                  <img src={image} alt='' />
                </div>
                <h3>{title}</h3>
                <div className='portfolio__item-cta'>
                <a href={github} alt='' class='btn'>GitHub</a>
                </div>
              </article>
               )
             })
           }
        </div>
    </section>
  )
}

export default Portfolio