import React from 'react'
import {BsLinkedin, BsGithub} from 'react-icons/bs'
 

const HeaderSocial = () => {
  return (
    <div className="header__socials">
        <a href='https://linkedin.com' target="_blank"><BsLinkedin /></a>
        <a href='https://github.com/ralph534' target="_blank"><BsGithub /></a>
        <a href='https://linkedin.com' target="_blank"></a> 
    </div>
  )
}

export default HeaderSocial