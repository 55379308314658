import React from 'react'
import './nav.css'
import {AiOutlineHome, AiOutlineUser} from 'react-icons/ai'
import {GiJourney} from 'react-icons/gi'
import {RiServiceLine} from 'react-icons/ri'
import {BiMessageSquareDetail} from 'react-icons/bi'
import {useState} from 'react'


const Nav = () => {
  // default state of the nav icons
  const [activeNav, setNav] = useState('#home');


  return (
    <nav>
      {/* if the certain section is click and is being viewed, then icon shows that its active */}
      <a href='#home' onClick={() => setNav('#home')} className={activeNav === '#home' ? 'active' : ''}><AiOutlineHome /></a>
      <a href='#about' onClick={() => setNav('#about')} className={activeNav === '#about' ? 'active' : ''}><AiOutlineUser /></a>
      <a href='#experience' onClick={() => setNav('#experience')} className={activeNav === '#experience' ? 'active' : ''}><GiJourney /></a>
      <a href='#contact' onClick={() => setNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}><BiMessageSquareDetail /></a>
    </nav>
  )
}

export default Nav