import React from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {BsWhatsapp, BsLinkedin} from 'react-icons/bs'
import { useRef } from 'react'
import emailjs from 'emailjs-com'


const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_bfmvc3t', 'template_clrsq2m', form.current, '8sQU-lf8Eq3Ewv3SP')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      e.target.reset();
  };

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me!</h2>

      <div className='container contact__container'>
         <div className='contact__options'>
           <article className='contact__option'>
             <MdOutlineEmail className='contact__option-icon'/>
              <h4>Email</h4>
              <h5>ralphyetienne@yahoo.com</h5>
              <a href='mailto:ralphyetienne@yahoo.com' target='_blank'>Send a Message</a>
           </article>
           <article className='contact__option'>
             <BsLinkedin className='contact__option-icon'/>
              <h4>LinkedIn</h4>
              <a href='https://www.linkedin.com/in/ralph-etienner/' target='_blank'>Send a Message</a>
           </article>
           <article className='contact__option'>
             <BsWhatsapp className='contact__option-icon'/>
              <h4>WhatsApp</h4>
              <h5>+17867635104</h5>
              <a href='https://api.whatsapp.com/send?phone=+17867635104' target='_blank'>Send a Message</a>
           </article>
         </div>
         {/* END OF CONTACT OPTIONS */}
         <form ref={form} onSubmit={sendEmail}>
           <input type='text' name='name' placeholder='Your Full Name' required />
           <input type='text' name='email' placeholder='Your Email' required />
           <textarea name='message' rows='7' placeholder='Your Message' required></textarea>
           <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact