import React from 'react'
import './about.css'
import {FaAward} from 'react-icons/fa'
import  {FiUsers} from 'react-icons/fi'
import {VscFolderOpened} from 'react-icons/vsc'
import ME from '../../assets/image0(2).jpeg'


const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className='container about__container'>
         <div className='about__me'>
          <div className='about__me2'>
           <div className='about__me-image'>
              <img src={ME} alt='me' />
           </div>
          </div>
        </div>

           <div className='about__content'>
             <div className='about__cards'>
                <article className='about__card'>
                  <FaAward className='about__icon'/>
                   <h5>Experience</h5>
                   <small>3+ Years Self-Taught</small>
                </article>
                <article className='about__card'>
                  <FiUsers className='about__icon'/>
                   <h5>Clients</h5>
                   <small>
                     <ul className='client__list'>
                       <li>Friends and Family</li>
                       <li>Project Collabs</li>
                     </ul>
                   </small>
                </article>
                <article className='about__card'>
                  <VscFolderOpened className='about__icon'/>
                   <h5>Projects</h5>
                   <small>20+ completed</small>
                </article>
             </div>

             <p>
               hello people in the tech world!!!
             </p>
             <a href='#contact' className='btn btn-primary'>Let's Talk</a>
           </div>

      </div>
    </section>
  )
}

export default About